
<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  My books history
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  My books history
                </div>
              </div>
            </div>
          </div>
          <div class="card-body ">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-col cols="12" md="3">
              <v-select v-on:keyup.enter="getMyIssuedBooks()" v-model="search.type" :items="types"
                        item-text="text" item-value="value" outlined dense label="Type"></v-select>
              </v-col>
              <v-col cols="12" md="4" class="text-right">
                <v-btn @click="getMyIssuedBooks()" class="btn btn-primary btn-search " :loading="loading">
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </div>
            <div class="">
              <table class="table ">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">#</th>
                  <th class="px-3 wrap-column" style="max-width: 300px !important; white-space: pre-wrap;">Book title
                  </th>
                  <th class="px-3 wrap-column"></th>

                  <th class="px-3 wrap-column">Dates</th>

                </tr>
                </thead>
                <tbody v-if="issued_books.length > 0">
                <tr v-for="(issued_book, i) of issued_books" :key="i">
                  <td class="px-3 wrap-column">
                    <div  class="font-weight-bolder text-hover-primary mb-1" >
                      {{ issued_book.book_title }}
                    </div>&nbsp;

                    <div class="text-secondary">
                      <b>Book no.</b> {{ issued_book.book_no ? issued_book.book_no : 'N/A' }}
                    </div>
                    <div class="text-secondary" v-if="issued_book.book_accession_no">
                      <b>Book accession no.</b> {{ issued_book.book_accession_no ? issued_book.book_accession_no : 'N/A' }}
                    </div>

                  </td>
                  <td class="px-3 wrap-column" style="max-width: 300px !important; white-space: pre-wrap;">

                    <div class="text-secondary">
                      <b>Author:</b> {{ issued_book.book_author ? issued_book.book_author : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publisher:</b> {{ issued_book.book_publisher ? issued_book.book_publisher : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publication:</b> {{ issued_book.book_publication ? issued_book.book_publication : 'N/A'  }}
                    </div>

                  </td>
                  <td class="px-3 wrap-column">
                    <img class="cursor-pointer img-thumbnail" style="height: 80px" v-if="issued_book.user_image"
                                                    :src="issued_book.user_image" alt="">
                    <span v-else>-</span>
                  </td>

                  <td class="wrap-column">
                    <div class="text-secondary">
                        <span class="badge badge-secondary">Issue date: {{ issued_book.issue_date_text ? issued_book.issue_date_text : '-'
                          }}</span>
                    </div>
                    <div class="text-secondary  mt-3">
                        <span class="badge badge-secondary">Renew date: {{
                            issued_book.due_return_date_text ? issued_book.due_return_date_text : '-'
                          }}</span>
                    </div>
                    <div class="text-secondary  mt-3">
                        <span class="badge badge-secondary">Return date: {{ issued_book.renew_date_text ? issued_book.renew_date_text : '-'
                          }}
                        </span>
                    </div>
                    <div class="text-secondary  mt-3 ml-2" v-if="search.type=='currently'">
                      Remaining days: <span class="font-weight-medium "
                                            :class="issued_book.renew_required ? 'text-danger' : 'text-success'">
                          {{ `${issued_book.renew_required ? '-' + issued_book.remaining_renew_day : issued_book.remaining_renew_day}` }}
                        </span>
                    </div>
                  </td>


                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="10">
                    <p class="px-3 text-center">
                      <strong>No data available to display.</strong>
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import IssueService from "@/core/services/library/issue/IssueService";

const issueService = new IssueService();
export default
{
  data(){
    return{
      search:{
        type:'currently'
      },
      loading:false,
      issued_books:[],
      types:[
        {text:'Current issued',value:'currently'},
        {text:'Previously issued',value:'previously'},
      ]
    }
  },
  methods:{
    getMyIssuedBooks(){
      this.loading=true;
      issueService
          .issuedBook(this.search.type,this.search)
          .then(response => {
            this.issued_books = response.data.books
        })
          .catch((err) => {

          })
          .finally(error => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getMyIssuedBooks();
  }
}
</script>

